<template>
  <div class="container">
    <div style="font-size: 16px; margin: 0 0 20px 15px">引导页配置</div>
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="120px" v-loading="getLoading">
      <el-form-item label="首页图片" prop="bannerImg" :rules="[{ required: true, message: '请上传首页图片' }]">
        <ImgUpload v-model="formModel.form.bannerImg" :limit="1" />
      </el-form-item>

      <el-form-item label="销售中图片" prop="forSaleImg" :rules="[{ required: true, message: '请上传销售中图片' }]">
        <ImgUpload v-model="formModel.form.forSaleImg" :limit="1" />
      </el-form-item>
      <el-form-item label="销售中文案" prop="forSaleText" :rules="[{ required: true, message: '请输入销售中文案' }]">
        <el-input v-model="formModel.form.forSaleText" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="备货中图片" prop="inStockImg" :rules="[{ required: true, message: '请上传备货中图片' }]">
        <ImgUpload v-model="formModel.form.inStockImg" :limit="1" />
      </el-form-item>
      <el-form-item label="备货中文案" prop="inStockText" :rules="[{ required: true, message: '请输入备货中文案' }]">
        <el-input v-model="formModel.form.inStockText" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="销售状态" prop="status" :rules="[{ required: true }]">
        <el-radio-group v-model="formModel.form.status">
          <el-radio label="0">备货中</el-radio>
          <el-radio label="1">在售</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 操作 -->
      <el-form-item label=" ">
        <el-button type="primary" @click="submit" :loading="submitLoading" style="width: 180px">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import { cloneDeep } from 'lodash'
import ImgUpload from '@/components/ImgUpload'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'
import { ElMessage } from 'element-plus'
import { tryJSONParse } from '@/utils/utils'

const defaultForm = {
  bannerImg: '', // 首页图片
  forSaleImg: '', // 销售中图片
  forSaleText: '', // 销售中文案
  inStockImg: '', // 备货中图片
  inStockText: '', // 备货中文案
  status: '0', // 销售状态 0:备货中,1:在售(非空)
}

const getLoading = ref(false)
const submitLoading = ref(false)
const formRef = ref(null)
const formModel = reactive({
  form: cloneDeep(defaultForm),
})

onMounted(() => {
  getInfo()
})

/**
 * 方法
 */

function getInfo() {
  getLoading.value = true
  request({
    url: '/web/system/sysTag/getSysTagSaleStatus',
  })
    .finally(() => (getLoading.value = false))
    .then((data) => {
      const rawData = {
        ...defaultForm,
        ...tryJSONParse(data?.remark),
        status: data?.tagKey, // status 字段 没放在 remark里
      }
      formModel.form = rawData
    })
}

// 提交
async function submit() {
  await validateFormRef(formRef)
  submitLoading.value = true
  request({
    url: '/web/system/sysTag/updateSysTagSaleStatus',
    data: {
      ...formModel.form,
    },
  })
    .finally(() => {
      submitLoading.value = false
    })
    .then(() => {
      ElMessage.success('操作成功')
    })
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  overflow: hidden;
  border-radius: 3px;
  padding: 20px 10px;
  .form_box {
    width: 500px;
  }
}
</style>
